import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import Login from '../views/Login/Login.vue'
import StoreRedirect from '../views/StoreRedirect.vue'
import Politicas from '../views/Politicas.vue'
import InteractiveAvatar from '../views/InteractiveAvatar/InteractiveAvatar.vue'
// import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
// import Checkout from '@/components/checkout/index.vue'
import { NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/store-redirect',
    name: 'StoreRedirect',
    component: StoreRedirect
  },
  {
    path: '/politicas',
    name: 'Politicas',
    component: Politicas
  },
  {
    path: '/avatar',
    name: 'InteractiveAvatar',
    component: InteractiveAvatar
  },
  // {
  //   path: '/preguntas_frecuentes',
  //   name: 'PreguntasFrecuentes',
  //   component: PreguntasFrecuentes
  // },
  // {
  //   path: '/Checkout',
  //   name: 'Checkout',
  //   component: Checkout
  // }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
        return { selector: to.hash }
    } else {
        return { x: 0, y: 0 }
    }
},
  routes
})


router.beforeEach((to, from, next) => {
  const avatar_id = to.query.avatar_id;
  let token = localStorage.getItem('userToken')
	if(to.query && to.query.bt){
		if (!token) {
			localStorage.setItem('userToken', to.query.bt)
			token = to.query.bt
		}
	}
	
  if (!token && to.name !== 'Login' && to.name !== 'Home') {
    next({ name: 'Login', query: { redirect_to: to.path, avatar_id } });
  } else {
    next();
  }
});

export default router
