<template>
	<div v-if="selectedAvatar" class="videocall-container d-flex justify-center align-center">
		<v-btn v-if="!stream"
			class="back-button"
			@click="selectedAvatar = null">
			<v-icon
				large
			>
				mdi-chevron-left
			</v-icon>
			<div>Volver</div>
		</v-btn>
		<video
			v-if="stream"
			:srcObject.prop="stream"
			autoPlay
			playsInline
			style="width: 100%; height: 100%; object-fit: cover;">
			<track kind="captions" />
		</video>
		<div class="loading-text" v-else-if="isLoadingSession">Conectando ...</div>
		<div v-else class="selectedpreview-container d-flex flex-column justify-center align-center text-center pa-2" :style="'background-image: url(' + selectedAvatar.img_preview + ')'">
			<div class="loading-text black--text">{{ended ? 'Sesión Finalizada' : 'Sesión en Espera'}}</div>
			<div class="black--text muted">{{ended ? 'Si deseas puedes volver a llamar presionando el botón verde' : 'Si deseas puedes llamar presionando el botón verde'}}</div>
		</div>
		<div class="bottom-controllers d-flex justify-center pa-6">
			<v-btn v-if="!stream"
				x-large
				color="green"
      	fab
				:loading="isLoadingSession"
				@click="startSession">
				<v-icon
					large
					color="white"
				>
					mdi-phone
				</v-icon>
			</v-btn>
			<v-btn v-if="stream"
				x-large
				color="red"
      	fab
				@click="endSession">
				<v-icon
					large
					color="white"
				>
					mdi-close
				</v-icon>
			</v-btn>
		</div>
	</div>
	<div v-else class="select-avatar-container d-flex justify-center align-center">
		<v-container>
			<v-row>
				<v-col cols="12">
					<p class="font-weight-bold white--text title text-center mb-0">Por favor selecciona con quien prefieres conversar</p>
				</v-col>
				<v-col
					v-for="(avatar, index) in avatarList"
					:key="index"
					cols="12" sm="6" md="3"
				>
					<div
						class="avatar-select-container d-flex flex-column align-center"
						@click="selectAvatar(avatar)">
						<v-img :src="avatar.img_preview" class="avatar-image" />
						<div class="px-4" style="width: 100%;">
							<p class="my-1 text-left font-weight-bold">{{ avatar.name }}</p>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import  StartAvatarResponse  from "@heygen/streaming-avatar"
import StreamingAvatar, { AvatarQuality, StreamingEvents, TaskType, VoiceEmotion } from "@heygen/streaming-avatar"
import { newConversation, newMessageConversation } from '../../components/helpers/api/conversations'

export default {
    components: { StreamingAvatar, AvatarQuality, StreamingEvents, TaskType, VoiceEmotion, StartAvatarResponse },
    data () {
			return {
				tab: 0,
				isLoadingSession: false,
				isLoadingRepeat: false,
				stream: null,
				debug: '',
				knowledgeId: null,
				avatarId: 'ef08039a41354ed5a20565db899373f3',
				language: 'es',
				selectedAvatar: null,
				avatarList: [
					{
						name: 'Carlos',
						img_preview: 'https://files2.heygen.ai/avatar/v3/88d83ee011cc4dc693dad929a6b8acc4/full/2.2/preview_target.webp',
						avatar_id: '88d83ee011cc4dc693dad929a6b8acc4',
						voice: {
							voice_id: 'a1351936c9f04c3a80ea176f0ef9ec10'
						}
					},
					{
						name: 'Sofia',
						img_preview: 'https://files2.heygen.ai/avatar/v3/ef08039a41354ed5a20565db899373f3/full/2.2/preview_target.webp',
						avatar_id: 'ef08039a41354ed5a20565db899373f3',
						voice: {
							voice_id: 'ce03fd97e8074a8f935c298c7f68a0df'
						}
					},
					{
						name: 'Juan Carlos',
						img_preview: 'https://files2.heygen.ai/avatar/v3/43c34c4285cb4b6c81856713c70ba23b/full/2.2/preview_target.webp',
						avatar_id: '43c34c4285cb4b6c81856713c70ba23b',
						voice: {
							voice_id: 'e3bf80c9e519492ba6482dedaf7829a1'
						}
					},
					{
						name: 'Angélica',
						img_preview: 'https://files2.heygen.ai/avatar/v3/c20f4bdddbe041ecba98d93444f8b29b/full/2.2/preview_target.webp',
						avatar_id: 'c20f4bdddbe041ecba98d93444f8b29b',
						voice: {
							voice_id: '95e872563c014ef493aceff42de6f3e9'
						}
					}
				],
				data: StartAvatarResponse,
				text: '',
				mediaStream: null,
				avatar: {current: null},
				chatMode: 'text_mode',
				isUserTalking: false,
				sessionData: null,
				messages: [
					{"role": "system", "content": "Eres un asistente de salud de una aplicación llamada Aiduam. Tu género es femenino. En general, da respuestas cortas."},
					{"role": "system", "content": "Puedes entablar una comunicación con el usuario, trata de ser una compañía y guiarlo en temas de salud."},
				],
				socket: null,
				socketUrl: 'https://devapis.anubys.cl:3010',
				playing: false,
				bases: [],
				audioQueue: [],
				audioContext: null,
				text_response: [],
				isAvatarTalking: null,
				audio_avatar_talking: false,
				conversation_id: null,
				last_message: '',
				currentAvatarMessage: '',
        ended: false
      }
    },
    methods: {
			selectAvatar(avatar){
        this.selectedAvatar = avatar
				this.startSession()
			},
      async startSession() {
      try {
        this.isLoadingSession = true
				const conversation = await newConversation()
				if(conversation.code == 200){
					this.conversation_id = conversation.result.id
				}
        await this.fetchAccessToken()
        this.avatar.current = new StreamingAvatar({ token: this.token })

				this.avatar.current.on(StreamingEvents.AVATAR_TALKING_MESSAGE, (message) => {
					this.currentAvatarMessage += message.detail.message
				});
				this.avatar.current.on(StreamingEvents.USER_TALKING_MESSAGE, (message) => {
					const data = {conversation_id: this.conversation_id, role: 'user', message: message.detail.message, token: 0}
					newMessageConversation(data)
				});

				this.avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
					console.log("Avatar started talking", e);
					this.audio_avatar_talking  = true
				});
				this.avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
					console.log("Avatar stopped talking", e);
					this.audio_avatar_talking = false
					const data = {conversation_id: this.conversation_id, role: 'assistant', message: this.currentAvatarMessage, token: 0}
					newMessageConversation(data)
					this.currentAvatarMessage = ''
				});
				this.avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
					console.log("Stream disconnected");
					this.endSession();
				});
				this.avatar.current.on(StreamingEvents.STREAM_READY, (event) => {
					console.log(">>>>> Stream ready:", event.detail);
					this.stream = event.detail
					setTimeout(() => {
						this.avatar.current.speak({ text: 'Hola, como estás? Soy ' + this.selectedAvatar.name + '. ¿Me podrías indicar cual es tu nombre?', task_type: TaskType.REPEAT })
					}, 1000);
				});
				this.avatar.current.on(StreamingEvents.USER_START, (event) => {
					console.log(">>>>> User started talking:", event);
					this.avatar.current.interrupt()
					this.isUserTalking = true
				});
				this.avatar.current.on(StreamingEvents.USER_STOP, (event) => {
					console.log(">>>>> User stopped talking:", event);
					this.isUserTalking = false
				});
				this.data = await this.avatar.current.createStartAvatar({
					avatarName: this.selectedAvatar.avatar_id,
					voice: {
						voiceId: this.selectedAvatar.voice.voice_id,
						rate: 1,
						emotion: VoiceEmotion.FRIENDLY,
					},
					knowledgeId: '69dbd5aa24504bbc8396962005262841',
					quality: 'medium',
					language: 'es',  
				})
				
				const voice_chat = await this.avatar.current.startVoiceChat();
			} catch (error) {
				console.error("Error starting avatar session:", error);
			} finally {
				this.isLoadingSession  = false
			}
		},
		async handleSpeak(text) {
			this.isLoadingRepeat = true;
				if (!this.avatar.current) {
					this.debug = "Avatar API not initialized";
					return;
				}

			if (this.chatMode === 'voice_mode') {
				await this.avatar.current.startVoiceChat();
			}

			await this.avatar.current.speak({ text, task_type: TaskType.REPEAT }).catch((e) => {
				this.debug = e.message;
			});

			this.isLoadingRepeat = false;
			this.text = '';
		},
		async endSession() {
			await this.avatar.current.stopAvatar();
			this.stream = undefined;
			this.ended = true;
      this.selectAvatar = null
		},
		handleChangeChatMode(v) {
			if (v === 'text_mode') {
				this.avatar.current.closeVoiceChat();
			} else {
				this.avatar.current.startVoiceChat();
			}
			this.chatMode = v;
		},
		async fetchAccessToken() {
			try {
				const response = await fetch("https://api.heygen.com/v1/streaming.create_token", {
					method: "POST",
						headers: {
						"x-api-key": process.env.VUE_APP_HEYGEN_API_KEY,
						"Content-Type": "application/json",
					},
				});
				const res = await response.json()
				const token = await res.data.token

				this.token = token 
				return token;
			} catch (error) {
				console.error("Error fetching access token:", error);
			}
		},
	},
	watch: {
		stream: {
			immediate: true,
			handler(newStream) {
				if (newStream && this.$refs.mediaStream) {
					this.$refs.mediaStream.srcObject = newStream;
					this.$refs.mediaStream.onloadedmetadata = () => {
						this.$refs.mediaStream.play();
						this.debug = 'Playing';
					};
				}
			},
		},
	},
  onBeforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  },
  mounted() {
    const foundAvatar = this.avatarList.find(avatar => avatar.avatar_id === this.$route.query.avatar_id);
    if (foundAvatar) {
      this.selectedAvatar = foundAvatar
    }
		// newConversation().then(res => {
		// 	this.startSession()
		// })
		// this.startSession()
  },
}
</script>

<style lang="css" scoped>
	.select-avatar-container{
		background: url('/images/fondo-7.svg')  no-repeat;
		background-size: cover;
		background-attachment: fixed;
		width: 100%;
		height: 100%;
	}
	.avatar-select-container{
		border-radius: 10px;
    overflow: hidden;
    background: white;
		border: 1px solid white;
		cursor: pointer;
	}
	/deep/ .avatar-select-container:hover .avatar-image .v-image__image{
		background-size: 120%;
	}
	.avatar-image{
		width: 100%;
		overflow: hidden;
	}
	.videocall-container{
		background: url('/images/fondo-7.svg')  no-repeat;
		background-size: cover;
		background-attachment: fixed;
		width: 100%;
		height: 100vh;
		position: relative;
	}
	.selectedpreview-container{
		width: 100%;
		height: 100%;
		position: relative;
		background-position: center;
    background-size: cover;
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
	}
	.selectedpreview-container .avatar-image{
		height: 100%;
		object-fit: cover;
	}
	.bottom-controllers{
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
	}
	.loading-text{
		font-size: 25px;
		font-weight: bold;
		color: white;
	}
	.back-button{
		position: absolute;
		top: 10px;
    left: 10px;
    z-index: 999;
	}
</style>